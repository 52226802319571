import React, { FC } from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Layout } from './layout'

export const MDXLayout: FC = ({ children }) => {

  const P = (props: unknown) => <p className="text-base mb-2" {...props} />

  const H1 = (props: unknown) => <h1 className="text-4xl font-extrabold mb-4" {...props} />
  const H2 = (props: unknown) => <h2 className="text-3xl font-bold mb-3 pt-2" {...props} />
  const H3 = (props: unknown) => <h3 className="text-2xl font-semibold mb-2 pt-1" {...props} />
  const H4 = (props: unknown) => <h3 className="text-xl font-semibold mb-2 pt-1" {...props} />
  const H5 = (props: unknown) => <h3 className="text-lg font-semibold mb-2 pt-1" {...props} />
  const H6 = (props: unknown) => <h3 className="text-base font-semibold mb-2 pt-1" {...props} />

  const Blockquote = (props: unknown) => <blockquote className="" {...props} />

  const UL = (props: unknown) => <ul className="list-disc list-outside py-3" {...props} />
  const OL = (props: unknown) => <ol className="list-decimal list-inside py-6" {...props} />
  const LI = (props: unknown) => <li className="py-1" {...props} />

  const Table = (props: unknown) => <table className="" {...props} />
  const Thead = (props: unknown) => <thead className="" {...props} />
  const Tbody = (props: unknown) => <tbody className="" {...props} />
  const TR = (props: unknown) => <tr className="" {...props} />
  const TD = (props: unknown) => <td className="" {...props} />

  const Pre = (props: unknown) => <pre className="" {...props} />
  const Code = (props: unknown) => <code className="" {...props} />
  const Em = (props: unknown) => <em className="italic" {...props} />
  const Strong = (props: unknown) => <strong className="" {...props} />
  const Del = (props: unknown) => <del className="" {...props} />
  const Hr = (props: unknown) => <hr className="" {...props} />
  const A = (props: unknown) => <a className="text-vivid-light-blue-300 hover:text-vivid-light-blue-500 focus:text-vivid-light-blue-500" {...props} />
  const Img = (props: unknown) => <img className="" {...props} />

  const ThematicBreak = (props: unknown) => <div className="py-4" {...props} />

  const components = {
    p: P,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
    blockquote: Blockquote,
    thematicBreak: ThematicBreak,
    ul: UL,
    ol: OL,
    li: LI,
    table: Table,
    thead: Thead,
    tbody: Tbody,
    tr: TR,
    'td/th': TD,
    pre: Pre,
    code: Code,
    em: Em,
    strong: Strong,
    del: Del,
    hr: Hr,
    a: A,
    img: Img,
  }

  return (
    <MDXProvider components={components}>
      <Layout>
        <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
          <div className="text-cool-grey-700">
            {children}
          </div>
        </div>
      </Layout>
    </MDXProvider>
  )
}